<template>
  <Layout>
    <div class="base-width">
      <div class="c-card md-auth md-auth-1">
        <div class="title s26 color-2 font-medium">工程配套服务商认证</div>

        <form class="form s16 color-2" @submit.prevent="handleSubmit">

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">联系人姓名</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入联系人姓名" v-model="form.suppliers_name">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">联系人手机号</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入联系人手机号" v-model="form.suppliers_phone" disabled>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">公司名称</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入公司名称" v-model="form.company_name">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">公司规模</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入公司规模" v-model="form.team_number">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">服务类型</p>
                <p class="color-6 s14 p_tips">(可多选)</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseCheckbox :options='serviceArr' v-model="serviceType" name='serviceType' @change="serviceChange"
                :key="serviceKey">
              </BaseCheckbox>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">营业执照</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseFile v-model="licenseArr" placeholder="请上传营业执照" :max='1' @change="licenseChange" :key="licenKey">
              </BaseFile>
            </div>
          </div>

          <div class="form-item form-item--idcard">
            <label class="form-item_label required">
              <div>
                <p class="p_label">身份证图片</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseIdCard type='front' v-model="idcard_1" @change="forntChange" :key="frontKey"></BaseIdCard>
              <BaseIdCard type='back' v-model="idcard_2" @change="backChange" :key="backKey + 'a'"></BaseIdCard>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">邀请码</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入邀请码" v-model="form.code">
            </div>
          </div>

          <!-- <div class="form-item form-item--agreement">
            <label class="c-radio">
              <input type="checkbox" hidden v-model="agree">
              <div class="c-radio_icon"></div>
            </label>
            申请即表示同意易筑平台<router-link to='/agreement'>《入驻协议》</router-link>
          </div> -->

          <div class="form-item form-item--submit">
            <BaseButton size='large' @click="ok">提交</BaseButton>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { SettledSubmitApi, SettledReadyApi } from "@/request/api/user";
export default {
  created() {
    SettledReadyApi({ type: 5 }).then(({ status, data }) => {
      if (status == 200) {
        if (data.info) {
          this.form = data.info;
          if (data.info.license) {
            this.licenseArr[0] = data.info.license;
          }
          this.licenKey++;
          if (data.info.id_card_img.length) {
            this.idcard_1 = data.info.id_card_img[0];
          }
          if (data.info.id_card_img.length > 1) {
            this.idcard_2 = data.info.id_card_img[1];
          }
          this.frontKey++;
          this.backKey++;
          this.serviceType = data.info.service_type.split(',');
          this.serviceKey++;
        }
        this.form.suppliers_phone = data.mobile;
        data.service_type.map(item => {
          this.serviceArr.push({ value: item, name: item })
        })
      }
    })
  },
  data() {
    return {
      form: {
        type: 5,
        suppliers_name: "",
        suppliers_phone: "",
        company_name: "",
        id_card_img: [],
        license: "",
        team_number: "",
        code: "",
        service_type: ""
      },
      licenseArr: [],
      licenKey: 0,
      frontKey: 0,
      backKey: 0,
      serviceKey: 0,
      agree: true,
      idcard_1: null,
      idcard_2: null,
      serviceArr: [],
      agree: true,
      serviceType: [],
    };
  },
  methods: {
    serviceChange(e) {
      this.form.service_type = e.toString();
    },
    // 营业执照改变
    licenseChange(arr) {
      this.form.license = arr[0];
      this.licenseArr = arr;
    },
    // 身份证正面
    forntChange(str) {
      this.form.id_card_img[0] = str;
    },
    // 身份证反面
    backChange(str) {
      this.form.id_card_img[1] = str;
    },
    ok() {
      let obj = JSON.parse(JSON.stringify(this.form))
      obj.id_card_img = obj.id_card_img.toString();
      SettledSubmitApi(obj).then(({ status, data, msg }) => {
        if (status == 200) {
          this.$message.success(msg);
          this.$router.push(`/join`);
        }
      })

    },
    handleSubmit() {

    }
  }
};
</script>

<style lang="scss" scoped></style>